import { Link, useNavigate } from "react-router-dom";

export const NotFound = () => {
	const navigate = useNavigate();
	return (
		<div className="flex flex-col items-center justify-center mt-40 ">
			<div className="text-xl font-bold">Where are you?!</div>
			<div className="text-2xl font-bold">404 Not Found</div>

			<div className="flex gap-4 mt-4">
				<button onClick={() => navigate(-1)} className="px-4 py-2 bg-blue-500 text-white rounded">
					Go back
				</button>
				<Link to="/" className="px-4 py-2 bg-green-500 text-white rounded">
					Go home
				</Link>
			</div>
		</div>
	);
};
