import { Link } from "react-router-dom";

const Header = () => (
	<div className="justify-between flex mb-10">
		<div className="join bg-transparent flex justify-between w-full">
			<Link to="/" className="btn bg-transparent join-item border-none">
				Alberto Herrera
			</Link>
			<div>
				<Link to="/experience" className="btn bg-transparent join-item border-none">
					Experience
				</Link>
				<Link to="/projects" className="btn bg-transparent join-item border-none">
					Projects
				</Link>
			</div>
		</div>
	</div>
);

export { Header };
