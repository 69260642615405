import { ProjectCard } from "./ProjectCard";
import Fitty from "./images/fitty.png";
import { motion } from "framer-motion";

enum Technologies {
	React = "React",
	Node = "Node",
	Express = "Express",
	Python = "Python",
}
type ValueOf<T> = T[keyof T];

export interface Project {
	name: string;
	description: string;
	technologies: ValueOf<Technologies>[];
	image: string;
}

const Projects = () => {
	const projects: Project[] = [
		{
			name: "Fitty",
			description: "(Work in Progress) Fitness tracker app created with Expo and NestJS",
			technologies: ["Expo", "NestJS", "Postgres", "Prisma"],
			image: Fitty,
		},
	];

	return (
		<motion.div
			className="text-center flex flex-col justify-center"
			initial={{
				y: "100%",
			}}
			animate={{
				y: 0,
			}}
			exit={{
				y: "-100%",
			}}
		>
			<h4 className="text-center m-auto text-4xl font-bold">Projects</h4>
			<div className="flex flex-col text-center">
				<div className="join mx-auto"></div>
				<div className="grid grid-cols-3 p-4 rounded-box mx-auto mb-16">
					{projects.map((project, index) => (
						<ProjectCard key={project.name} project={project} index={index} />
					))}
				</div>
			</div>
		</motion.div>
	);
};
export { Projects };
