import { Job } from "../JobDescription";
import BadgerImage from "../images/badger-logo.png";
import Levelworks from "../images/levelworks.png";

export const jobs: Job[] = [
	{
		companyName: "Badger",
		startDate: new Date(2019, 7, 15),
		endDate: new Date(2021, 11, 31),
		image: BadgerImage,
		description:
			"During my experience at Badger I worked as a fullstack developer\nThis gave me the opportunity to expeand my knowledge on maps and CRM's\nBesides that, I found my passion to deliver clean code and follow good principles",
	},
	{
		companyName: "Level.works",
		startDate: new Date(2022, 12, 1),
		endDate: new Date(),
		image: Levelworks,
		description:
			"During the work at Level.works I've contributed to the development of multiple features.\nSome of the most interesting parts, can be the integration with external financial services,\nthe delivery of a white label product, and the maintenance and replication\nof the infrastructure of it.",
	},
];
