export interface Job {
	companyName: string;
	startDate: Date;
	endDate: Date;
	description: string;
	image: string;
}

const JobDescription = ({
	startDate,
	endDate,
	description,
	companyName,
	image,
	isEven,
}: Job & { isEven: boolean }) => (
	<div
		className={`mt-20 flex flex-col  md:flex-row items-center justify-evenly mb-14 gap-16 ${
			isEven ? "md:flex-row" : "md:flex-row-reverse"
		}`}
	>
		<img alt={`${companyName} avatar`} className="w-24" src={image} />
		<div className={`${isEven ? "lg:ml-0" : "lg:mr-0"} ml-6 flex flex-col max-w-sm lg:max-w-none`}>
			<h3 className="text-l font-bold my-3">{companyName}</h3>
			<p className="text-xs font-thin my-3">
				{startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
			</p>

			<div>{description}</div>
		</div>
	</div>
);

export { JobDescription };
