import { JobDescription } from "./JobDescription";
import { jobs } from "./data/jobs";
import { motion } from "framer-motion";

const Experience = () => {
	return (
		<>
			{/* <motion.div className="bg-black fixed w-full" /> */}
			<motion.div
				className="flex max-h-screen flex-col"
				initial={{
					y: "100%",
				}}
				animate={{
					y: 0,
				}}
				exit={{
					y: "-100%",
				}}
			>
				<h4 className="text-center m-auto text-4xl font-bold">Experience</h4>
				<div className="flex flex-col px-36">
					{jobs.map((job, index) => (
						<JobDescription {...job} isEven={!!(index % 2)} key={index} />
					))}
				</div>
			</motion.div>
		</>
	);
};
export { Experience };
