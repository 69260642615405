import { Route, Routes, useLocation } from "react-router-dom";
import { Header } from "./Header";
import { Home } from "./Home";
import { Experience } from "./Experience";
import { NotFound } from "./NotFound";
import { Projects } from "./Projects";
import { AnimatePresence } from "framer-motion";

function App() {
	const location = useLocation();
	return (
		<div className="bg-white p-3">
			<Header />
			<AnimatePresence mode="wait">
				<Routes location={location} key={location.pathname}>
					<Route path="/" element={<Home />} />
					<Route path="/experience" element={<Experience />} />
					<Route path="/projects" element={<Projects />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</AnimatePresence>
		</div>
	);
}

export default App;
