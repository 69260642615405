import { ReactComponent as LinkedinLogo } from "./images/linkedin.svg";
import { ReactComponent as GithubLogo } from "./images/github.svg";
import { ReactComponent as StackoverflowLogo } from "./images/stackoverflow.svg";
import { motion } from "framer-motion";

const Home = () => (
	<motion.div
		className="flex items-center flex-col justify-center mt-20 "
		initial={{ y: "100%" }}
		animate={{ y: 0 }}
		exit={{ y: "-100%" }}
	>
		<div className="flex items-center flex-col mb-5 w-screen md:w-auto">
			<h1 className="text-4xl md:text-8xl font-bold font-ibm">Software </h1>
			<h1 className="text-5xl md:text-9xl font-bold font-ibm">Engineer</h1>
		</div>

		<div className="divider w-[50%] self-center my-10"></div>
		<div className="join">
			<ul className="menu menu-horizontal bg-base-200 rounded-box border-none bg-transparent">
				<li>
					<a href="https://github.com/alberturria" target="_blank" rel="noreferrer ">
						<GithubLogo className="inline-block h-5 w-5 fill-current" />
					</a>
				</li>
				<li>
					<a href="https://www.linkedin.com/in/albertoherrerav/" target="_blank" rel="noreferrer ">
						<LinkedinLogo className="inline-block h-5 w-5 fill-current" />
					</a>
				</li>
				<li>
					<a
						href="https://stackoverflow.com/users/16995821/alberto-herrera-vargas"
						target="_blank"
						rel="noreferrer "
					>
						<StackoverflowLogo className="inline-block h-5 w-5 fill-current" />
					</a>
				</li>
			</ul>
		</div>
	</motion.div>
);

export { Home };
