import { Project } from "./Projects";

const ProjectCard = ({ project }: { project: Project; index: number }) => (
	<div
		className="card w-96 bg-base-300 shadow-xl m-4 hover:shadow-2xl transition ease-in-out delay-420 hover:-translate-y-1 hover:scale-105 hover:bg-blue-300 hover:bg-opacity-20 duration-400 "
		key={project.name}
	>
		<figure>
			<img className="rounded-box" src={project.image} alt="Shoes" />
		</figure>
		<div className="card-body">
			<h2 className="card-title">{project.name}</h2>
			<p>{project.description}</p>
			<div className="card-actions justify-end">
				{project.technologies.map((technology) => (
					<div key={technology.toString()} className="badge badge-outline">
						{technology.toString()}
					</div>
				))}
			</div>
		</div>
	</div>
);

export { ProjectCard };
